<template>
  <transition name="mega-fade">
    <div class='wrapper absolute left-0 right-0 top-130' id='mega-menu'>
      <Tabs v-if='menuItemSelected && menuItemSelected.sub_nav' :tabTitle='menuItemSelected.sub_nav.item' :heap="heapAttribute">
        <template v-slot:[i] v-for="(subList, i) in menuItemSelected.sub_nav.item" :key='i'>
          <ul class='sub-items ma-0 pa-0 flex flex-wrap flex-column box-border items-baseline' :style="{'max-height': heightSubMenu}">
            <li v-for='(item, idx) in subList' :key='idx' class='fs-12 lh-14 mb-12 db hand box-border self-start'>
<!--              <a :href='goToURL(item.url)' @click='openURL(menuItemSelected.name)'><span class='gray-dark name'>{{item.name}}</span></a>-->
              <router-link :to='goToURL(item.url)' @click.native='openURL(menuItemSelected.name)'><span class='gray-dark name'>{{item.name}}</span></router-link>
            </li>
          </ul>
        </template>
      </Tabs>
    </div>
  </transition>
</template>

<script>
import Tabs from './items/Tabs'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'
import { useHeapStore } from '~/store/pinia/heap'
import { DESKTOP, LARGE_DESKTOP, PHONE } from '~/constant/common'

export default {
  name: 'MegaMenu',
  components: { Tabs },
  setup() {
    const route = useRoute()
    const generalStore = useGeneralInfoStore()
    const heapStore = useHeapStore()

    const clientWindowWidth = computed(() => generalStore.generalData.windowWidth)
    const dynamicColumn = computed(() => {
      if (clientWindowWidth.value > LARGE_DESKTOP) {
        return 8
      } else if (clientWindowWidth.value > DESKTOP) {
        return 6
      } else if (clientWindowWidth.value < PHONE) {
        return 3
      }
      return 4
    })
    const selectedIndex = computed(() => generalStore.generalData.tabIndexSelected)
    const menuItemSelected = computed(() => generalStore.generalData.megaMenuInfor.itemSelected)
    const countItemsSubMenu = computed(() => {
      if (menuItemSelected.value && menuItemSelected.value.sub_nav) {
        let subMenuKey = Object.keys(menuItemSelected.value.sub_nav.item).sort()
        if (!subMenuKey[selectedIndex.value]) return 0
        return menuItemSelected.value.sub_nav.item[subMenuKey[selectedIndex.value]].length
      }
      return 0
    })
    const heightSubMenu = computed(() => {
      return `${Math.ceil(countItemsSubMenu.value / dynamicColumn.value)  * 26}px`
    })

    const heapAttribute = computed(() => {
      const selectedItem = menuItemSelected?.value?.name
      switch(selectedItem) {
        case "destinations":
          return "browse-destination-submenu"
        case "local designers":
          return "browse-local-designer-submenu"
        case "explore":
          return "browse-explore-submenu"
        default:
          return ""
        }
    })

    const goToURL = (url) => {
      const routeURL = route.path
      if(routeURL !== url) return `/${url}`
      return route.fullPath
    }

    watch(
      () => route.name,
      () => {
        generalStore.setTabIndex(0)
        const data = {
          status: false,
          itemSelected: ''
        }
        generalStore.setMegaMenuStatus(data)
      },
    )

    const setGallerySource = async () => {
      await heapStore.changeGalleryHeap('header')
    }

    const openURL = (item) => {
      switch(item) {
        case "destinations":
        case "explore":
        setGallerySource();
        break;
      }

      generalStore.setTabIndex(0)
      const data = {
        status: false,
        itemSelected: {}
      }
      generalStore.setMegaMenuStatus(data)
    }

    return {
      openURL, menuItemSelected, goToURL, heapAttribute, heightSubMenu
    }
  }
}
</script>

<style scoped lang='scss'>
#mega-menu {
  background-color: #F8FBFF;
  box-shadow: 0px 10px 25px rgba(164, 203, 250, 0.4);
}
.sub-items {
  min-height: 104px;
  width: 180px;
  li {
    width: 180px;
    .name:hover {font-weight: 700; color: black; font-family: 'proximanova-bold';}
  }
}
</style>
