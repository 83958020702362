<template>
  <div
    id="topInfo"
    v-if="showTopBar"
    class="flex items-center justify-between flex-wrap bg-gray-branding h-40 fs-12 lh-14 relative z-1000 wrapper"
  >
    <div class="flex items-center phone">
      <img :src="`${ASSETS_CDN}/images/general/phone-call.svg`" height="12" width="12" alt="Phone" />
      <a :href="`tel: ${phoneSSR}`" @click="heapPhoneTracking"
        ><span class="fw-700 pl-10 black">{{ phoneSSR }}</span></a
      >
      <template v-if="isDesktop">
        <!--        <img class='px-10' src='https://s3-cdn.designerjourneys.com/static/icons/australia.svg' alt='Australia'>-->
        <img class="px-10" :src="`${ASSETS_CDN}/images/general/australia.svg`" height="16" alt="Australia" />
        <span>Monday to Friday 8am - 9pm (AEDT)</span>
      </template>
    </div>
    <div v-if="showRightNavigation" class="fs-12 lh-14 fw-700 flex items-center justify-end">
      <slot name="right-start"></slot>
      <DropdownCurrency></DropdownCurrency>
      <slot name="right-end"></slot>
    </div>
  </div>
  <template v-if="isDesktop">
    <div v-show="openMegaMenu" class="hp-100 wp-100 fixed top-0 left-0" style="z-index: 99" @click="closeMega"></div>
  </template>
  <!--  <header class='sticky top-0 z-9998 bg-white' v-ClickOutside="closeMega" id="header">-->
  <header :class="['top-0 z-999 bg-white', isSearchResultPage ? 'relative' : 'sticky']" id="header">
    <div id="main-header">
      <div class="flex-wrapper pl-40 pr-40 relative mb-pl-20 mb-pr-20">
        <div v-if="isDesktop" class="hp-100 wp-100 absolute top-0 left-0" @click="closeMega"></div>
        <div class="left relative">
          <div class="left-logo">
            <router-link v-if="!affiliateEnquiry" to="/" title="Go to Homepage" aria-label="Go to Homepage">
              <img
                :src="`${ASSETS_CDN}/images/general/dj-logo-b.svg`"
                :width="isDesktop ? 244 : 163"
                :height="isDesktop ? 30 : 20"
                alt="Logo - Designer Journeys"
              />
            </router-link>
            <div v-else>
              <a class="fs-0 lh-1 dib" :href="affiliateInfo.website" style="height: 40px">
                <img class="fs-0 lh-1" :src="affiliateInfo.logo" height="40" />
              </a>
              <div class="flex items-center" style="padding-top: 5px">
                <span class="fs-12 pr-6">powered by</span>
                <a
                  class="fs-0 lh-1"
                  href="https://www.designerjourneys.com/"
                  target="_blank"
                  title="Go to DesignerJourneys"
                  aria-label="Go to DesignerJourneys"
                >
                  <img class="fs-0 lh-1" :src="`${ASSETS_CDN}/images/general/dj-logo-b.svg`" height="14" alt="Logo - DesignerJourneys" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showRightNavigation" class="right" :class="[newHeader ? 'new-right' : '']">
          <template v-if="isDesktop">
            <SearchMenu />
            <Navigation :navigation="navigation" :headerType="headerType" />
            <slot></slot>
          </template>
          <router-link
              @click="heapEnquireCaptureTracking"
              class="button"
              :class="[newHeader ? 'button-white-transparent' : '']"
              v-if="isDesktopOrTablet && !showEnquiryButton"
              title="Design Your Trip"
              aria-label="Design Your Trip"
              :to="designURL()"
            >
              {{ newHeader ? 'Enquire' : 'Design Your Trip' }}
          </router-link>
          <div class="pl-40 flex items-center relative">
            <NumberMessages />
            <client-only>
              <DropdownAccount v-if="isDesktop" />
              <MenuMobile v-else />
            </client-only>
          </div>
        </div>
        <div v-if="showPhoneMB && isMobileOrTablet">
          <a :href="`tel: ${phoneSSR}`" @click="heapPhoneTracking">
            <img :src="`${ASSETS_CDN}/images/general/phone_call_new.svg`" height="12" width="12" alt="Phone" />
          </a>
        </div>
      </div>
    </div>

    <div id="menumenu" class="relative" :class="{ 'header-wrap': openMegaMenuMB && isMobileOrTablet }">
      <template v-if="isDesktop">
        <MegaMenu v-show="openMegaMenu" :class="{ active: openMegaMenu }"></MegaMenu>
      </template>
      <template v-else>
        <MegaMenuMobile
          v-show="openMegaMenuMB"
          :list="navigationMB"
          :headerType="headerType"
          :class="{ active: openMegaMenuMB }"
          :userData="userInfo"
        ></MegaMenuMobile>
      </template>
    </div>
    <div id="headerBottom"></div>
  </header>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { DESKTOP, PHONE } from '~/constant/common'
import enquiryTravellerApi from '~/composables/api/enquiryTravellerApi'
// import ClickOutside from '~/directives/ClickOutside'
import {assetCDN, publicDomain} from '~/helper'
// import { getCookie } from '~/mixins/cookie'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import { debounceHeapTracking } from '~/mixins/heap'
import { debounceAmplitudeScriptTracking } from '~/mixins/amplitude'
import DropdownAccount from './elements/DropdownAccount/DropdownAccount'
import DropdownCurrency from './elements/DropdownCurrency/DropdownCurrency'
import MegaMenu from './elements/MegaMenu/MegaMenu'
import MegaMenuMobile from './elements/MegaMenu/MegaMenuMobile'
import MenuMobile from './elements/MenuMobile/MenuMobile'
import Navigation from './elements/Navigation/NavigationDefault'
import NumberMessages from './elements/NumberMessages/NumberMessages'
import SearchMenu from './elements/SearchMenu/SearchMenu.vue'
import { useGalleryStore } from '~/store/pinia/gallery'
import { useTripPlanStore } from '~/store/pinia/tripPlan'
import { useAuthStore } from '~/store/pinia/auth'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'

    const props = defineProps({
      navigation: {
      type: [Object, Array],
      },
      navigationMB: {
        type: [Object, Array],
      },
      headerType: {
        type: String,
        default: 'Traveller', // Traveller, Agent, DesignAgent
      },
      showRightNavigation: {
        type: Boolean,
        default: true,
      },
      showTopBar: {
        type: Boolean,
        default: true,
      },
      showPhoneMB: {
        type: Boolean,
        default: true,
      },
      newHeader: {
        type: Boolean,
        default: true,
      },
    })

    const route = useRoute()
    const galleryStore = useGalleryStore()
    const tripPlanStore = useTripPlanStore()
    const authStore = useAuthStore()
    const generalStore = useGeneralInfoStore()
    const { isDesktop: isDesktopDevice, isTablet: isTabletDevice, isMobile: isMobileDevice } = useDevice()

    const openMegaMenu = computed(() => generalStore.generalData.megaMenuInfor.status)
    // const megaMenuItems = computed(() => generalStore.generalData.megaMenuInfor.itemSelected)
    const openMegaMenuMB = computed(() => generalStore.generalData.megaMenuInforMB.status)
    const windowWidth = computed(() => generalStore.generalData.windowWidth)
    const isDesktop = computed(() => isDesktopDevice && windowWidth.value > DESKTOP)
    const isTablet = computed(() => isTabletDevice || (windowWidth.value < DESKTOP && windowWidth.value > PHONE))
    const isMobile = computed(() => isMobileDevice && windowWidth.value <= PHONE)
    const isDesktopOrTablet = computed(() => isDesktop.value || isTablet.value)
    const isMobileOrTablet = computed(() => isMobile.value || isTablet.value)
    const affiliateInfo = ref({})

    // const phone = computed(() => {
    //   return generalStore.detectCountryInfo.phone
    // })

    const phoneSSR = computed(() => {
      //const country_code = process.env.DEFAULT_COUNTRY_CODE || useGetCookie('country_code').value
      /*const country_code = useGetCookie('country_code').value || store.state?.generalStore?.detectCountryInfo?.country_code
      const continent_code = useGetCookie('continent_code').value
      return getOperationNumber(country_code, continent_code)*/
      return generalStore.detectCountryInfo.phone
    })

    const ASSETS_CDN = assetCDN()
    // const is_verified = getCookie('is_verified=')
    const is_verified = useGetCookie('is_verified').value

    const designURL = () => {
      if (route.name === 'types-gallery' && route.params?.types_) {
        // if(process.env.VUE_APP_ENQUIRY_SINGLE) return { name: 'enquiry-submit-destination', params: { galleryTypes: route.params.types } }
        return {
          name: 'enquiry-submit-destination',
          params: { galleryTypes: route.params?.types_ },
        }
      }
      if ((route.name === 'gallery-local-designers' && route.params.country) || (route.name === 'country-travel-guide' && route.params.country)) {
        // if(process.env.VUE_APP_ENQUIRY_SINGLE) return { name: 'enquiry-submit-destination', params: { galleryTypes: route.params.country } }
        return {
          name: 'enquiry-submit-destination',
          params: { galleryTypes: route.params.country },
        }
      } else {
        // if(process.env.VUE_APP_ENQUIRY_SINGLE) return { name: 'enquiry-submit-global' }
        return { name: 'enquiry-submit-global' }
      }
    }

    const destination = computed(() => galleryStore.galleryInfor?.name)
    const trip_plan_name = computed(() => tripPlanStore?.dataTrip?.name)

    // const isNewHomepage = computed(() => domain === 'traveller' && route.name === 'home' && props.newHeader)
    const showEnquiryButton = computed(() => {
      const domain = publicDomain()

      const routeNameTV = ['enquiry-confirmation','local-designer','trip-plan-bookable','trip-gallery','bookings-listing','booking-details','enquiries-listing','account-settings','search-result','login-traveller','signup-traveller','signup-agent','forgot-password','how-it-works','about-us','contact-us','become-local-designer','private-group-tours','sign-up-travel-agent','terms-of-use','privacy-policy','data-protection','cookie-declaration','traveller-protections']
      const routeNameTA = ['enquiry-steps','trip-gallery-enquiry-steps','gallery-enquiry-steps','enquiry-local-designer','enquiry-group-booking']
      return (((domain === 'traveller') && (route.name === 'home')) || ((domain === 'traveller') && (route.name === 'trip-plan'))  || ((domain === 'traveller') && (routeNameTV.indexOf(route.name) !== -1))  || ((domain === 'agent') && (routeNameTA.indexOf(route.name) !== -1))) && props.newHeader
    })

    const heapPhoneTracking = () => {
      debounceHeapTracking('Make Phone Call', {
        source_widget: 'top-bar',
      })
      debounceAmplitudeScriptTracking('Make Phone Call', {
        source_widget: 'top-bar',
      })
    }

    const heapEnquireCaptureTracking = () => {
      debounceHeapTracking('View Enquiry', {
        source_widget: 'header',
        source_type: route.name,
        type: 'b2c',
        destination: route.name === 'types-gallery' ? destination.value : '',
        trip_plan_name: route.name === 'trip-plan' ? trip_plan_name.value : '',
      })
      debounceAmplitudeScriptTracking('View Enquiry', {
        source_widget: 'header',
        source_type: route.name,
        type: 'b2c',
        destination: route.name === 'types-gallery' ? destination.value : '',
        trip_plan_name: route.name === 'trip-plan' ? trip_plan_name.value : '',
      })

      closeMega();
    }

    const closeMega = () => {
      generalStore.setTabIndex(0)
      const data = {
        status: false,
        itemSelected: '',
      }
      generalStore.setMegaMenuStatus(data)
    }

    const gotoPage = () => {
      return 'home'
    }

    const windowHeight = () => {
      const doc = document.documentElement
      const menu = document.getElementById('menumenu')
      if (menu) {
        let rectTop = menu.getBoundingClientRect().top
        doc.style.setProperty('--window-height', `${window.innerHeight - rectTop}px`)
      }
    }

    const userInfo = computed(() => {
      let data = authStore.getUserInfo
      // console.log('HeaderLayout userInfo: ',data.value)
      return data
    })

    const offsetY = ref(0)
    watch(openMegaMenuMB, (value) => {
      if (value) {
        windowHeight()
        offsetY.value = window.pageYOffset
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
        document.body.style.top = -offsetY.value + 'px'
        document.getElementById('header').style.zIndex = '9998'
      } else {
        document.documentElement.style.scrollBehavior = 'auto'
        document.body.style.position = 'static'
        window.scrollTo({
          top: offsetY.value,
          behavior: 'auto',
        })
        document.documentElement.style.scrollBehavior = 'smooth'
        document.getElementById('header').removeAttribute('style')
      }
    })

    const dataTrip = computed(() => tripPlanStore.dataTrip)

    const affiliateEnquiry = computed(() => {
      const isAffConfirm = route.name === 'enquiry-confirmation' && route?.query?.affiliate_id
      const affiliateTripPlan = route.name === 'trip-plan-bookable' && dataTrip.value?.aff_id
      const aff_id = route?.query?.affiliate_id || dataTrip.value?.aff_id
      if (isAffConfirm || affiliateTripPlan) getAffiliateInfo(aff_id)
      return isAffConfirm
    })

    const getAffiliateInfo = async (id) => {
      try {
        const resp = await enquiryTravellerApi.getAffiliateInfo({
          aff_id: id,
        })
        affiliateInfo.value = resp.data.data
        // store.commit('tripPlanStore/setDataAffiliate', affiliateInfo.value)
        tripPlanStore.setDataAffiliate(affiliateInfo.value)
      } catch (e) {
        console.log(e)
      }
    }

    const isSearchResultPage = computed(() => {
      return route.name === 'search-result'
    })

    onMounted(() => {
      windowHeight()
      window.addEventListener('resize', windowHeight)
      window.addEventListener('scroll', windowHeight)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', windowHeight)
      window.addEventListener('scroll', windowHeight)
    })

</script>

<style lang="scss">
@import './HeaderStyle.scss';
.num-msg {
  background-color: #f05d5d;
  top: -8px;
  border-radius: 2px;
}
@media only screen and (max-width: 300px) {
  .phone {
    margin-bottom: 10px;
  }
  .wrapper {
    height: 50px;
  }
}
.header-wrap {
  height: var(--window-height);
}
</style>
