<template>
  <div class='tabs-header'>
    <ul class='flex items-center'>
      <li v-for='(item, index) in getTitle' :key='index' @click='selectTab(index)' class="mr-20">
        <button :class="['tab-button flex items-center justify-center h-26 px-12 fs-12 lh-14 fw-700 gray-dark br-14',{'active': (index === selectedIndex)}]" :h="heap">{{ item }}</button>
      </li>
      <template v-if="!isLocalDesignerTab">
        <template v-if='$device.isDesktop'>
          <li><router-link :to="goToURL()" @click="openURL()" class='fs-12 lh-14 fw-700 hand db w-max-content'><span class='gray-dark'>Browse All Trips</span></router-link></li>
        </template>
        <template v-else>
          <li><router-link :to="goToURL()" @click="openURLMB()" class='fs-12 lh-14 fw-700 hand db w-max-content'><span class='gray-dark'>Browse All Trips</span></router-link></li>
        </template>
      </template>
    </ul>
  </div>
  <div v-for="(item, index) in getTitle" :key="index" :class="['tab-content relative',{'active': (index === selectedIndex)}]">
<!--    <div v-if="index === selectedIndex">-->
    <div class='content-wrapper' v-show='index === selectedIndex'>
      <slot :name="item"></slot>
    </div>
  </div>

</template>

<script>
import { computed, watch } from 'vue'
import { DESKTOP } from '~/constant/common'
import { useRoute } from 'vue-router'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'

export default {
  name: 'Tabs',
  props: ['tabTitle', 'heap'],
  data() {
    return {
      DESKTOP
    }
  },
  setup(props) {
    const route = useRoute()
    const generalStore = useGeneralInfoStore()

    const selectedIndex = computed(() => generalStore.generalData.tabIndexSelected)
    const isLocalDesignerTab = computed(() => generalStore.generalData?.megaMenuInfor?.itemSelected?.name?.includes('designers') ||  generalStore.generalData?.megaMenuInforMB?.itemSelected?.includes('designers'))

    const selectTab = (i) => {
      generalStore.setTabIndex(i)
    }

    const openURL = () => {
      generalStore.setTabIndex(0)
      const data = {
        status: false,
        itemSelected: ''
      }
      generalStore.setMegaMenuStatus(data)
    }

    const openURLMB = () => {
      generalStore.setTabIndex(0)
      const data = {
        status: false,
        statusSubNav: false
      }
      generalStore.setMegaMenuStatusMB(data)
    }

    const getTitle = computed(() => {
      let title = Object.keys(props.tabTitle).sort()
      return title
    })

    const goToURL = () => {
      const routeURL = route.path
      if(routeURL !== '/trip-gallery') return '/trip-gallery'
      return route.fullPath
    }

    watch(selectedIndex, (name) => {
      selectTab(name)
    })

    return {
      selectedIndex, selectTab, openURL, getTitle, openURLMB, goToURL, isLocalDesignerTab
    }
  }
}
</script>

<style lang='scss' scoped>
.tabs-header {
  border-bottom: 1px solid #A4CBFA;
  padding-top: 30px; padding-bottom: 20px;
  @include tablet {
    border-bottom: none;
    padding-top: 20px; overflow-x: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 1px; height: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
      background-clip: content-box;
      background-color: transparent;
    }
  }
}
.tab-button {
  border: 1px solid #D0D0D0;
  background-color: white;
  transition-property: background-color, border-color, color;
  transition-duration: time(fast);
  transition-timing-function: ease(inout);
  width: max-content;
  &:hover {
    background-color: #F1F1F1;
  }
  &.active {
    color: black; border-color: black;
  }
}
.content-wrapper {
  padding-top: 20px; padding-bottom: 18px;
  @include phone {
    padding-bottom: 0;
    padding-top: 0;
  }
}
</style>
